import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import { getEnvironment } from '../utils/getEnvironment';

const SITE_NAME = 'StaffingAssignmentsUserLibrary';
const SERVICE_NAME = "ALL";

const metricsErrorHandler = (err: Error) => {
  console.error(err);
};

class MetricsPublisher extends KatalMetrics.Publisher {
  constructor(saulEnvironment: string) {
    const { domain, realm } = getEnvironment(saulEnvironment);
    const metricsDriver = new KatalMetricsDriverSushi.Builder()
      .withDomainRealm(domain, realm)
      .withErrorHandler(metricsErrorHandler)
      .build();
    const initialMetricsContext = new KatalMetrics.Context.Builder()
      .withSite(SITE_NAME)
      .withServiceName(SERVICE_NAME)
      .build();

    super(metricsDriver, metricsErrorHandler, initialMetricsContext);
  }
}

export default MetricsPublisher;
