import { useCallback, useEffect, useRef, useState } from 'react';
import { Client, gql } from 'urql';
import {
  GetUserStaffingAssignmentDocument,
  UserStaffingAssignment,
} from '../graphql/generated';
import { ClientIdentifier } from '../types';
import MetricsPublisher from '../metrics/MetricsPublisher';
import { cleanString, publishCounterMetrics } from '../utils/metricUtils';
import { FETCH_USER_STAFFING_ASSIGNMENT_ERROR } from '../constants/metricConstants';

gql`
  query GetUserStaffingAssignment($nodeId: String, $associateId: String) {
    getUserStaffingAssignment(nodeId: $nodeId, associateId: $associateId) {
      nodeId
      associateId
      processSegment {
        processSegmentId
        processSegmentName
        workstationsType
        workstations {
          workstationId
          workstationAlias
          workstationName
          workstationType
          inputLocationIds
          outputLocationIds
          creationType
          status
          maxAssociates
          requiredEquipment
        }
        costBucket
      }
      workstation {
        workstationId
        workstationAlias
        workstationName
        workstationType
        inputLocationIds
        outputLocationIds
        creationType
        status
        maxAssociates
        requiredEquipment
      }
    }
  }
`;

type AssociateStaffingAssignmentsResult = {
  staffingAssignmentsByAssociateId?: Map<string, UserStaffingAssignment>;
  loading: boolean;
  error?: unknown;
};

const FETCH_INTERVAL_MS = 15000;

interface UseAssociateStaffingAssignmentsProps {
  client: Client;
  associateIds: string[];
  metricsPublisher: MetricsPublisher | undefined;
  clientIdentifier: ClientIdentifier;
  disabled?: boolean;
}

const useAssociateStaffingAssignments = ({
  client,
  associateIds,
  metricsPublisher,
  clientIdentifier,
  disabled = false,
}: UseAssociateStaffingAssignmentsProps): AssociateStaffingAssignmentsResult => {
  const [
    staffingAssignmentsByAssociateId,
    setStaffingAssignmentsByAssociateId,
  ] = useState<Map<string, UserStaffingAssignment>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();
  const fetchInterval = useRef<number | undefined>();

  const fetchUserStaffingAssignment = useCallback(
    async (
      associateId: string
    ): Promise<[string, UserStaffingAssignment | null | undefined]> => {
      const result = await client.query(
        GetUserStaffingAssignmentDocument,
        { associateId },
        { requestPolicy: 'network-only' }
      );
      if (result.error || !result.data) {
        if (result.error && metricsPublisher) {
          publishCounterMetrics(
            metricsPublisher,
            `${FETCH_USER_STAFFING_ASSIGNMENT_ERROR}-${cleanString(result.error?.message)}`,
            clientIdentifier
          );
        }
        throw new Error(
          `An error occurred fetching the assignment: ${result.error?.message ?? 'No data'}`
        );
      }
      return [associateId, result.data.getUserStaffingAssignment];
    },
    [client, metricsPublisher, clientIdentifier]
  );

  const fetchAssignments = useCallback(async () => {
    setLoading(true);

    try {
      const assignments = (
        await Promise.all(
          associateIds.map((associateId) =>
            fetchUserStaffingAssignment(associateId)
          )
        )
      ).filter((entry): entry is [string, UserStaffingAssignment] => {
        const assignment = entry[1];
        return assignment !== null && assignment !== undefined;
      });
      setStaffingAssignmentsByAssociateId(new Map(assignments));
      setError(undefined);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [fetchUserStaffingAssignment, associateIds]);

  useEffect(() => {
    if (disabled) {
      clearInterval(fetchInterval.current);
      return;
    }

    fetchAssignments();
    fetchInterval.current = window.setInterval(
      fetchAssignments,
      FETCH_INTERVAL_MS
    );

    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [fetchAssignments, disabled]);

  return {
    staffingAssignmentsByAssociateId,
    loading,
    error,
  };
};

export default useAssociateStaffingAssignments;
