import { useCallback, useEffect, useRef, useState } from 'react';
import { Client, gql } from 'urql';
import { GetTrainedRolesForSaulAssociatesDocument } from '../graphql/generated';
import MetricsPublisher from '../metrics/MetricsPublisher';
import { ClientIdentifier } from '../types';
import { cleanString, publishCounterMetrics } from '../utils/metricUtils';
import { FETCH_TRAINED_ROLES_FOR_ASSOCIATE_ERROR } from '../constants/metricConstants';

gql`
  query getTrainedRolesForSAULAssociates($nodeId: String) {
    trainedRolesForSAULAssociates(nodeId: $nodeId) {
      trainedRoles {
        associateId
        roles
      }
    }
  }
`;

type TrainedRolesResult = {
  trainedRoles?: string[];
  loading: boolean;
  error?: unknown;
};

const FETCH_INTERVAL_MS = 600000;

interface UseTrainedRolesForAssociateProps {
  client: Client;
  metricsPublisher: MetricsPublisher | undefined;
  clientIdentifier: ClientIdentifier;
  disabled?: boolean;
}

const useTrainedRolesForAssociate = ({
  client,
  metricsPublisher,
  clientIdentifier,
  disabled = false,
}: UseTrainedRolesForAssociateProps): TrainedRolesResult => {
  const [trainedRoles, setTrainedRoles] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();
  const fetchInterval = useRef<number | undefined>();
  const fetchTrainedRoles = useCallback(async () => {
    setLoading(true);

    try {
      const result = await client.query(
        GetTrainedRolesForSaulAssociatesDocument,
        {},
        { requestPolicy: 'network-only' }
      );
      if (result.error || !result.data) {
        if (result.error && metricsPublisher) {
          publishCounterMetrics(
            metricsPublisher,
            `${FETCH_TRAINED_ROLES_FOR_ASSOCIATE_ERROR}-${cleanString(result.error?.message)}`,
            clientIdentifier
          );
        }
        setError(
          `An error occurred fetching trained roles: ${result.error?.message ?? 'No data'}`
        );
      } else {
        setTrainedRoles(
          result.data.trainedRolesForSAULAssociates?.trainedRoles[0]?.roles?.filter(
            (item): item is string => item !== null && item !== undefined
          ) || []
        );
        setError(undefined);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [client, clientIdentifier, metricsPublisher]);

  useEffect(() => {
    if (!disabled) {
      fetchTrainedRoles();
      fetchInterval.current = window.setInterval(
        fetchTrainedRoles,
        FETCH_INTERVAL_MS
      );
    }

    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [fetchInterval, fetchTrainedRoles, disabled]);

  return {
    trainedRoles,
    loading,
    error,
  };
};

export default useTrainedRolesForAssociate;
