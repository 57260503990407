import {
  Assignment,
  NO_ASSIGNMENT,
  NODE_NOT_SET
} from '@amzn/staffing-assignments-user-library-web-app';
import { ClientIdentifier, clientTypeMap } from '../types';
import { ProcessSegment, WorkstationDefinition } from '../graphql/generated';
import { getClientIdentifier } from './workstationUtils';

const mapWidgetAssignment = (
  workstation: WorkstationDefinition | undefined | null,
  processSegment: ProcessSegment | undefined | null,
  associateId: string = NO_ASSIGNMENT,
  nodeId: string = NODE_NOT_SET,
  clientIdentifier: ClientIdentifier = 'UNKNOWN',
  unauthorized?: boolean
): Assignment => {
  const { workstationType, workstationName } = workstation || {};
  const { processSegmentName, workstationsType } = processSegment || {};
  const location =
    workstationName ?? (unauthorized ? processSegmentName : undefined);
  const type = workstationType || workstationsType || '';
  const identifier = getClientIdentifier(
    workstation,
    processSegment,
    clientIdentifier
  );
  const clientType = clientTypeMap[identifier];
  return {
    name: processSegmentName || type.replace(/_/g, ' ').toLowerCase(),
    location,
    clientIdentifier: identifier,
    clientType,
    associateId,
    nodeId
  };
};

export default mapWidgetAssignment;
