import { useCallback, useEffect, useRef, useState } from 'react';
import { Client, gql } from 'urql';
import { GetRightStationNodeConfigurationDocument } from '../graphql/generated';
import MetricsPublisher from '../metrics/MetricsPublisher';
import { ClientIdentifier } from '../types';
import { cleanString, publishCounterMetrics } from '../utils/metricUtils';
import { FETCH_NODE_CONFIGURATION_ERROR } from '../constants/metricConstants';

gql`
  query GetRightStationNodeConfiguration($nodeId: String) {
    getRightStationNodeConfiguration(nodeId: $nodeId) {
      enabledFeatures
      saulIgnoredWorkstationTypes
    }
  }
`;

type GetNodeConfigurationResult = {
  enabledFeatures?: string[];
  saulIgnoredWorkstationTypes?: string[];
  loading: boolean;
  error?: unknown;
};

const FETCH_INTERVAL_MS = 90000;

interface UseNodeConfigurationProps {
  client: Client;
  metricsPublisher: MetricsPublisher | undefined;
  clientIdentifier: ClientIdentifier;
  disabled?: boolean;
}

const useNodeConfiguration = ({
  client,
  metricsPublisher,
  clientIdentifier,
  disabled = false,
}: UseNodeConfigurationProps): GetNodeConfigurationResult => {
  const [enabledFeatures, setEnabledFeatures] = useState<string[]>();
  const [saulIgnoredWorkstationTypes, setSaulIgnoredWorkstationTypes] =
    useState<string[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();
  const fetchInterval = useRef<number | undefined>();

  const getNodeDetails = useCallback(async () => {
    setLoading(true);
    try {
      const result = await client.query(
        GetRightStationNodeConfigurationDocument,
        {},
        { requestPolicy: 'network-only' }
      );
      if (result.error || !result.data) {
        if (result.error && metricsPublisher) {
          publishCounterMetrics(
            metricsPublisher,
            `${FETCH_NODE_CONFIGURATION_ERROR}-${cleanString(result.error?.message)}`,
            clientIdentifier
          );
        }
        throw new Error(
          `An error occurred fetching the node configuration: ${result.error?.message ?? 'No data'}`
        );
      }
      setEnabledFeatures(
        result.data.getRightStationNodeConfiguration?.enabledFeatures
      );
      setSaulIgnoredWorkstationTypes(
        result.data.getRightStationNodeConfiguration
          ?.saulIgnoredWorkstationTypes
      );
      setError(undefined);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [client, clientIdentifier, metricsPublisher]);

  useEffect(() => {
    if (!disabled) {
      getNodeDetails();
      fetchInterval.current = window.setInterval(
        getNodeDetails,
        FETCH_INTERVAL_MS
      );
    }

    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [disabled, fetchInterval, getNodeDetails]);

  return {
    enabledFeatures,
    saulIgnoredWorkstationTypes,
    loading,
    error,
  };
};

export default useNodeConfiguration;
