export const SET_WORKSTATION_ID = 'setWorkstationId';
export const ENFORCEMENT_EXEMPTED_DUE_TO_DEPENDENCY_ERROR_STATE =
  'enforcmentExemptedDueToDependencyErrorState';
export const FETCH_USER_STAFFING_ASSIGNMENT_ERROR =
  'fetchUserStaffingAssignmentError';
export const FETCH_ASSOCIATE_AUTHORIZED_AT_WORKSTATION_ERROR =
  'fetchAssociateAuthorizedAtWorkstationError';
export const FETCH_NODE_CONFIGURATION_ERROR = 
  'fetchNodeConfigurationError';
export const SAVE_ASSOCIATE_LOGIN_ERROR =
  'saveAssociateLoginError';
export const FETCH_TRAINED_ROLES_FOR_ASSOCIATE_ERROR =
  'fetchTrainedRolesForAssociateError';

