export enum Domain {
    TEST = 'test',
    PROD = 'prod',
  }
  export enum Realm {
    US_AMAZON = 'USAmazon',
    EU_AMAZON = 'EUAmazon',
  }
  
  type Environment = {
    domain: Domain;
    realm: Realm;
  };
  
  const TEST_ENVIRONMENT = {
    domain: Domain.TEST,
    realm: Realm.US_AMAZON,
  };
  
  const PROD_NA_ENVIRONMENT = {
    domain: Domain.PROD,
    realm: Realm.US_AMAZON,
  };
  
  const PROD_EU_ENVIRONMENT = {
    domain: Domain.PROD,
    realm: Realm.EU_AMAZON,
  };
  export const getEnvironment = (saulEnvironment: string): Environment => {
    switch (saulEnvironment) {
      case 'beta':
        return TEST_ENVIRONMENT;
      case 'na-prod':
        return PROD_NA_ENVIRONMENT;
      case 'eu-prod':
        return PROD_EU_ENVIRONMENT;
      default:
        return TEST_ENVIRONMENT;
    }
  };