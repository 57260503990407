import MetricsPublisher from '../metrics/MetricsPublisher';

export const publishCounterMetrics = (
  metricsPublisher: MetricsPublisher,
  counterName: string,
  methodName?: string | undefined
) => {
  metricsPublisher
    .newChildActionPublisherForMethod(methodName ?? counterName)
    .publishCounterMonitor(counterName, 1);
};

/**
 * Strips out all non-alphabetical characters for the purposes of emitting metrics,
 * as katal will block emitting metrics with certain characters
 */
export const cleanString = (input: string | undefined) => {
  return input ? input.replace(/[^a-zA-Z]/g, '') : '';
};
